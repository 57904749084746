<script lang="ts">
	import { getTranslate } from "../utils/getTranslate";
	import translations from "./Partners.translations.json";
	import type { Partner } from "../../../core/schema/Partner";
	import PartnersCarousel from "./Sliders/PartnersCarousel.svelte";

	export let partners: Partner[];

	const translate = getTranslate(translations);
</script>

<div class="bg-primary rounded-lg px-0 py-12 lg:py-14">
	<h2 class="px-1 text-center text-white sm:px-10 lg:text-[2rem]">{translate("efficiency")}:</h2>
	<div class="mt-8 flex flex-wrap items-center justify-center gap-8 sm:gap-x-12 sm:gap-y-10 lg:mt-12">
		<PartnersCarousel groupedPartners={partners} />
	</div>
</div>

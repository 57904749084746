<script lang="ts">
	import * as Glide from "@glidejs/glide";
	import type { Action } from "svelte/action";
	import type { Partner } from "../../../../core/schema/Partner";
	import { disableGlidePerSlideAmount } from "../../utils/disableGlidePerSlideAmount";
	import DynamicPicture from "../Picture/DynamicPicture.svelte";

	export let groupedPartners: Partner[];

	const glide: Action = (element) => {
		if (groupedPartners.length <= 1) {
			return;
		}

		const glideOptions = {
			type: "carousel",
			startAt: 0,
			perView: 9,
			autoplay: 1750,
			hoverpause: true,
			animationDuration: 1750,
			animationTimingFunc: "linear",
			dragThreshold: false,
			gap: 0,
			keyboard: false,
			breakpoints: {
				1024: { perView: 6 },
				640: { perView: 4 },
				475: { perView: 3 },
				400: { perView: 2 },
			},
		} satisfies Partial<Glide.Options>;

		// eslint-disable-next-line new-cap
		const glide = new Glide.default(element, glideOptions).mount();
		glide.on("resize", () => {
			disableGlidePerSlideAmount(glide, element);
		});

		return {
			destroy(): void {
				glide.destroy();
			},
		};
	};
</script>

<div use:glide class="glide relative flex h-16 items-center">
	<div class="glide__track grow" data-glide-el="track">
		<div class="glide__slides">
			{#each groupedPartners as partner, index}
				<div class="glide__slide px-2" class:pl-2={index === 0} class:pr-2={index === groupedPartners.length - 1}>
					<a
						role={partner.webUrl ? "link" : "none"}
						href={partner.webUrl}
						class="relative flex h-8 shrink-0 items-center justify-center rounded-[1.72rem]"
						target="_blank"
						rel="noopener noreferrer"
					>
						<DynamicPicture
							class="m-auto h-8 w-auto scale-100 object-contain transition-[transform] duration-300"
							imgClass="h-full object-contain text-white fill-white"
							image={partner.logo}
							alt={partner.name}
							width={140}
							height={40}
							bucket="storage"
							loading="eager"
						/>
					</a>
				</div>
			{/each}
		</div>
	</div>
</div>

<style>
	@import "../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";
</style>

<script lang="ts">
	import { getTranslate } from "../utils/getTranslate";
	import Link from "./Link.svelte";
	import translations from "./HomepageAboutUs.translations.json";
	import aboutSm from "../assets/images/about/VertiFlex-team-2.jpg?webp&h=405&imagetools";
	import aboutMd from "../assets/images/about/VertiFlex-team-2.jpg?webp&h=470&imagetools";
	import aboutLg from "../assets/images/about/VertiFlex-team-2.jpg?webp&h=320&imagetools";
	import aboutXl from "../assets/images/about/VertiFlex-team-2.jpg?webp&h=472&imagetools";

	import StaticPicture from "./Picture/StaticPicture.svelte";
	import arrowRightLong from "../../../core/assets/icons/arrow-right-long.svg?raw";

	const translate = getTranslate(translations);
</script>

<div class="mb-4 flex flex-col gap-2 overflow-hidden lg:grid lg:grid-cols-2">
	<div class="order-2 grid place-items-center lg:order-1">
		<StaticPicture
			image={aboutXl}
			alt={translate("niceToMeetYou")}
			height={472}
			width={708}
			loading="eager"
			class="relative aspect-[3/2] w-full"
			imgClass="rounded-lg inset-0 absolute"
			sourcesets={{
				"<md": aboutSm,
				">md": aboutMd,
				">lg": aboutLg,
				">xl": aboutXl,
			}}
		/>
	</div>
	<div class="order-1 flex flex-col gap-6 rounded-lg md:p-16 lg:order-2 lg:gap-2">
		<h2 class="font-spaceGrotesk mx-[initial] mt-6 pt-0 text-xl leading-[1] lg:mb-6 lg:mt-0">
			{translate("niceToMeetYou")}
		</h2>
		<p class="m-0 p-0 leading-6">{translate("content")}</p>
		<div class="flex lg:mt-4">
			<Link asButton variant="primary" icon={arrowRightLong} href={translate("readMoreLink")}>
				{translate("readMore")}
			</Link>
		</div>
	</div>
</div>

<script lang="ts">
	import Partners from "../components/Partners.svelte";
	import HomepageBanner from "../components/HomepageBanner.svelte";
	import Layout from "../components/Layout.svelte";
	import ReferencesSlider from "../components/Sliders/ReferencesSlider.svelte";
	import SolutionCards from "../components/SolutionCards.svelte";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./Homepage.translations.json";
	import type { HomepageProps } from "./HomepageProps";
	import HomepageServices from "../components/HomepageServices.svelte";
	import HomepageArticles from "../components/HomepageArticles.svelte";
	import HomepageAboutUs from "../components/HomepageAboutUs.svelte";

	const translate = getTranslate(translations);

	type $$Props = HomepageProps;
	const { partners, categories, articles, references, vertinodeAiProduct } = $$restProps as $$Props;
</script>

<Layout>
	<div class="container">
		<HomepageBanner />

		<section class="mb-12 lg:mb-24">
			<h2 class="mt-12 text-center text-lg sm:text-xl md:mt-16 md:text-2xl">{translate("ourSolutions")}</h2>
			<SolutionCards {categories} {vertinodeAiProduct} />
		</section>

		<section class="mb-12 lg:mb-24">
			<Partners {partners} />
		</section>

		{#if references.length > 0}
			<section class="mb-12 lg:mb-24">
				<ReferencesSlider {references} />
			</section>
		{/if}

		<section class="mb-12 lg:mb-24">
			<HomepageServices />
		</section>

		{#if articles.length > 0}
			<section class="mb-12 lg:mb-24">
				<HomepageArticles {articles} />
			</section>
		{/if}

		<section>
			<HomepageAboutUs />
		</section>
	</div>
</Layout>

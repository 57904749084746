<script lang="ts">
	import { getTranslate } from "../utils/getTranslate";
	import Link from "./Link.svelte";
	import translations from "./HomepageBanner.translations.json";
	import posterImage from "../assets/images/hp-banner.jpg?h=750&webp&imagetools";
	import arrowRightLong from "../../../core/assets/icons/arrow-right-long.svg?raw";
	import { solutionsPathMap } from "../../../core/schema/paths/solutionsPathMap";
	import { getGlobalSettings } from "../getGlobalSettings";
	import videoHpMp4 from "../assets/videos/vertiflex-sestrih_web-fhd-optimized-4MB.mp4";
	import videoHpWebm from "../assets/videos/vertiflex-sestrih_web-fhd-optimized-4MB.webm";
	import { nbspify } from "../../../core/utils/nbspify";

	const { TENANT_LOCALE } = getGlobalSettings();
	const translate = getTranslate(translations);
</script>

<div class="mb-4 flex flex-col-reverse gap-2 overflow-hidden lg:grid lg:grid-cols-2">
	<div class="rounded-lg bg-gray-300 p-4 sm:p-6 md:p-8">
		<h1 class="mb-3 pt-0 leading-[1] tracking-[-0.2rem] md:mb-6 lg:mb-10 lg:text-[3.5rem] xl:text-[5rem]">
			{nbspify(translate("title"))}
		</h1>
		<p class="text-xs leading-[1.4] md:text-sm lg:w-2/3 lg:text-base">{translate("content")}</p>
		<div class="mt-4 flex flex-col gap-2 sm:flex-row md:mt-10 lg:mt-[3.75rem]">
			<Link asButton variant="primary" icon={arrowRightLong} href="/{solutionsPathMap[TENANT_LOCALE]}">
				{translate("primaryBtn")}
			</Link>
			<Link asButton variant="secondary" icon={arrowRightLong} href="#contact-form">
				{translate("secondaryBtn")}
			</Link>
		</div>
	</div>

	<div class="relative h-[20rem] rounded-lg sm:h-[25rem] lg:h-full">
		<video
			poster={posterImage}
			autoplay
			muted
			loop
			playsinline
			class="absolute inset-0 h-full w-full max-w-[initial] rounded-lg object-cover"
			><source src={videoHpMp4} type="video/mp4" /><source src={videoHpWebm} type="video/webm" /></video
		>
	</div>
</div>
